import React from 'react';
import ReactTooltip from 'react-tooltip';

//CSS FILE IMPORT
import '../App.css';
import './About.css';

//ANIMATIONS IMPORT
import styled, { keyframes } from 'styled-components';
import { fadeInRightBig } from 'react-animations';

//ICONS IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUtensils,
  faDumbbell,
  faSwimmer,
  faTv,
} from '@fortawesome/free-solid-svg-icons';

function About() {
  //ANIMATION
  const FadeAnimation = keyframes`${fadeInRightBig}`;
  const FadeDiv = styled.div`
    animation: 2s ${FadeAnimation};
  `;

  //ICONS
  const iconFood = <FontAwesomeIcon icon={faUtensils} size='lg' />;
  const iconSport = <FontAwesomeIcon icon={faDumbbell} size='lg' />;
  const iconSwim = <FontAwesomeIcon icon={faSwimmer} size='lg' />;
  const iconTv = <FontAwesomeIcon icon={faTv} size='lg' />;

  return (
    <FadeDiv>
      <div className='fullpage aboutpage'>
        <div className='row'>
          <div className='photo col-lg-6'></div>
          <div className='about-text col-lg-6'>
            <h1>A little bit about me</h1>
            <div className='line'></div>
            <p>
              Born in <span>1992</span> (true 90's kid!). I grew up with
              walkmans, video cassettes and floppy disks. Who remembers those? I
              first worked with a computer when I was <span>12 years old</span>.
              No internet, just enjoying the classic game of Pinball and playing
              around in Paint.
            </p>
            <p>
              Studied languages in high school and got my bachelor degree in{' '}
              <span>Journalism in 2014</span>. Afterwards decided to give my
              life a new direction and focused myself on graphic design. After
              some years of experience in{' '}
              <span>offline design and marketing</span>, I decided to turn my
              focus more to <span>webdesign</span>, UI/UX design and recently low-code.
            </p>
            <h2>My favorite hobbies:</h2>
            <div className='icons'>
              <p data-tip data-for='foodTip' className='icon-paragraph'>
                {iconFood}
              </p>
              <p data-tip data-for='sportTip' className='icon-paragraph'>
                {iconSport}
              </p>
              <p data-tip data-for='swimTip' className='icon-paragraph'>
                {iconSwim}
              </p>
              <p data-tip data-for='iconTip' className='icon-paragraph'>
                {iconTv}
              </p>
            </div>

            <ReactTooltip id='foodTip' place='bottom' effect='solid'>
              Foody and wannabe chef
            </ReactTooltip>
            <ReactTooltip id='sportTip' place='bottom' effect='solid'>
              Dangerously good at kickboxing
            </ReactTooltip>
            <ReactTooltip id='swimTip' place='bottom' effect='solid'>
              Probably not the next Michael Phelps
            </ReactTooltip>
            <ReactTooltip id='iconTip' place='bottom' effect='solid'>
              Good at wasting hours watching television
            </ReactTooltip>
          </div>
        </div>
      </div>
    </FadeDiv>
  );
}

export default About;
