import React, { useState } from 'react';

//IMPORT ANIMATIONS
import styled, { keyframes } from 'styled-components';
import { fadeInRightBig } from 'react-animations';

//IMPORT CAROUSEL COMPONENTS
import SlideshowGEP from '../Components/SlideshowGEP';
import SlideshowCookr from '../Components/SlideshowCookr';
import SlideshowSyntra from '../Components/SlideshowSyntra';
import SlideshowTM from '../Components/SlideshowTM';
import SlideshowEM from '../Components/SlideshowEM';
import SlideshowMatchday from '../Components/SlideshowMatchday';
import SlideshowBubbleworks from '../Components/SlideshowBubbleworks';
import SlideshowBubblevision from '../Components/SlideshowBubblevision';
import SlideshowBM from '../Components/SlideshowBM';
import SlideshowCodeless from '../Components/SlideshowCodeless';
import SlideshowFoodware from '../Components/SlideshowFoodware';
import SlideshowSupplywise from '../Components/SlideshowSupplywise';

//IMPORT CSS FILES
import '../App.css';
import './Portfolio.css';

const pages = {
  home: <div className='portfolio-home'></div>,
  gep: <SlideshowGEP />,
  cookr: <SlideshowCookr />,
  syntra: <SlideshowSyntra />,
  tm: <SlideshowTM />,
  em: <SlideshowEM />,
  codeless: <SlideshowCodeless />,
  foodware: <SlideshowFoodware />,
  matchday: <SlideshowMatchday />,
  bubbleworks: <SlideshowBubbleworks />,
  bubblevision: <SlideshowBubblevision />,
  bm: <SlideshowBM />,
  supplywise: <SlideshowSupplywise />
};

function Portfolio() {
  //ANIMATION
  const FadeAnimation = keyframes`${fadeInRightBig}`;
  const FadeDiv = styled.div`
    animation: 2s ${FadeAnimation};
  `;
  const [page, setPage] = useState(pages.home);

  const content = (
    <div className='fullpage portfoliopage'>
      <div className='row'>
        <div className='portfolio-text col-lg-6'>
          <h1>My best projects</h1>
          <div className='line'></div>
          <ul>
          <li className={page === pages.codeless ? 'active' : ''} onClick={() => setPage(pages.codeless)}>
              Code-Less
              <span>Marketing, Social Media &amp; Website </span>
            </li>
            {/* <li className={page === pages.hvhn ? 'active' : ''} onClick={() => setPage(pages.hvhn)}>
              Huis v/h Nederlands
              <span>Application design in Bubble </span>
            </li> */}
            <li className={page === pages.foodware ? 'active' : ''} onClick={() => setPage(pages.foodware)}>
              Foodware
              <span>Website design in Webflow </span>
            </li>
            <li className={page === pages.supplywise ? 'active' : ''} onClick={() => setPage(pages.supplywise)}>
              SupplyWise
              <span>Website design in Bubble </span>
            </li>
          <li className={page === pages.bubblevision ? 'active' : ''} onClick={() => setPage(pages.bubblevision)}>
              BubbleVision
              <span>Website</span>
            </li>
            <li className={page === pages.gep ? 'active' : ''} onClick={() => setPage(pages.gep)}>
              Green Energy Park
              <span>Company Identity, illustrations &amp; logo</span>
            </li>
            <li className={page === pages.cookr ? 'active' : ''} onClick={() => setPage(pages.cookr)}>
              Cookr Application<span>Front-End Development</span>
            </li>
            <li className={page === pages.em ? 'active' : ''} onClick={() => setPage(pages.em)}>
              Effective Media / FLEET.be<span>Offline Design</span>
            </li>
            <li className={page === pages.tm ? 'active' : ''} onClick={() => setPage(pages.tm)}>
              Transport Media<span>Magazines</span>
            </li>
            <li className={page === pages.matchday ? 'active' : ''} onClick={() => setPage(pages.matchday)}>
              Matchday<span>Magazines</span>
            </li>
            <li className={page === pages.bubbleworks ? 'active' : ''} onClick={() => setPage(pages.bubbleworks)}>
              Bubbleworks<span>Company Identity &amp; logo</span>
            </li>
            <li className={page === pages.bm ? 'active' : ''} onClick={() => setPage(pages.bm)}>
              Buro Market
              <span>Company Identity, offline design &amp; website</span>
            </li>
            <li className={page === pages.syntra ? 'active' : ''} onClick={() => setPage(pages.syntra)}>
              Syntra Evening Course<span>Assignments and exercises</span>
            </li>
          </ul>
        </div>
        <div className='portfolio-photo col-lg-6'>{page}</div>
      </div>
    </div>
  );

  return page === pages.home ? (
    <FadeDiv>{content}</FadeDiv>
  ) : (
    <div>{content}</div>
  );
}

export default Portfolio;
