import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

//IMPORT COMPONENTS
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import About from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Skills from './Pages/Skills';
import Contact from './Pages/Contact';

//IMPORT CSS FILES
import './Reset.css';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Router>
        <div className='navbar-div'>
          <Navbar />
        </div>
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/skills' component={Skills} />
        <Route path='/portfolio' component={Portfolio} />
        <Route path='/contact' component={Contact} />
      </Router>
    </div>
  );
}

export default App;
