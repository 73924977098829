import React from 'react';
import { Alert } from 'reactstrap';

//IMPORT CSS FILE
import '../App.css';
import './Contact.css';

//ANIMATIONS IMPORT
import styled, { keyframes } from 'styled-components';
import { fadeInRightBig } from 'react-animations';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  render() {
    //ANIMATION
    const FadeAnimation = keyframes`${fadeInRightBig}`;
    const FadeDiv = styled.div`
      animation: 2s ${FadeAnimation};
    `;

    const { status } = this.state;

    //ALERT INLINE STYLING
    const alertStyle = {
      backgroundColor: '#ac7574',
      border: 'none',
      color: 'white',
      padding: '0.35rem 0.75rem',
      borderRadius: '0', 
    };

    const content = (
      <div className='fullpage contactpage'>
        <h1>Want to work together?</h1>
        <div className='line'></div>
        <p>
          Don't hesitate if you want to ask me something and just leave me a
          message.
        </p>
        <form
          onSubmit={this.submitForm}
          action='https://formspree.io/f/mknpldje'
          method='POST'
        >
          {status === 'ERROR' && (
            <div>
              {' '}
              <Alert style={alertStyle}>
                Something went wrong... Did you fill in the required fields?
              </Alert>
            </div>
          )}
          <input type='text' name='name' placeholder='Name' required />
          <input type='text' name='company' placeholder='Company' />
          <input type='text' name='phone' placeholder='Phone Number' />
          <input type='email' name='email' placeholder='E-mail' required />
          <textarea
            name='message'
            id=''
            cols='30'
            rows='5'
            placeholder='Type your message here...'
            required
          ></textarea>
          {status === 'SUCCESS' ? (
            <div>
              <Alert style={alertStyle}>
                Thanks for your message. I will contact you as soon as possible.
              </Alert>
            </div>
          ) : (
            <div className='btn-container'>
              <button className="btn">Send Message</button>
            </div>
          )}
        </form>
      </div>
    );

    return !status ? <FadeDiv>{content}</FadeDiv> : <div>{content}</div>;
  }

  //FORM SUBMIT FUNCTION
  //https://formspree.io/
  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }
}
