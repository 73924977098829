import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

//CSS FILE IMPORT
import './Navbar.css';

function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const toggleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const setNavCollapsed = () => setIsNavCollapsed(true);

  return ( 
    <div>
      {/* NAVBAR WEB VERSION */}
      <nav className='nav-web'>
        <ul>
          <li>
            <NavLink eventKey='4' activeClassName='active' to='/contact'>
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink eventKey='2' activeClassName='active' to='/skills'>
              Skills
            </NavLink>
          </li>
          <li>
            <NavLink eventKey='3' activeClassName='active' to='/portfolio'>
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink eventKey='1' activeClassName='active' to='/about'>
              About Me
            </NavLink>
          </li>
          <li class='logo'>
            <NavLink exact eventKey='5' activeClassName='active' to='/'>
              Y.
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* NAVBAR MOBILE VERSION */}
      <nav className='nav-mobile navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container'>
          <a className='navbar-brand' href='/'>
            <NavLink
              exact
              eventKey='5'
              activeClassName='active'
              to='/'
              onClick={setNavCollapsed}
            >
              Y.
            </NavLink>
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label='Toggle navigation'
            onClick={toggleNavCollapse}
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div
            className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
            id='navbarSupportedContent'
          >
            <ul className='navbar-nav'>
              <li>
                <NavLink
                  eventKey='1'
                  activeClassName='active'
                  to='/about'
                  onClick={setNavCollapsed}
                >
                  About Me
                </NavLink>
              </li>
              <li>
                <NavLink
                  eventKey='3'
                  activeClassName='active'
                  to='/portfolio'
                  onClick={setNavCollapsed}
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  eventKey='2'
                  activeClassName='active'
                  to='/skills'
                  onClick={setNavCollapsed}
                >
                  Skills
                </NavLink>
              </li>
              <li>
                <NavLink
                  eventKey='4'
                  activeClassName='active'
                  to='/contact'
                  onClick={setNavCollapsed}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
