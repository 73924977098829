import React from 'react';
import { Link } from 'react-router-dom';

//IMPORT ANIMATIONS
import styled, { keyframes } from 'styled-components';
import { fadeInRightBig } from 'react-animations';

//IMPORT CSS FILE
import '../App.css';
import './Home.css';

function Home() {
  //ANIMATION
  const FadeAnimation = keyframes`${fadeInRightBig}`;
  const FadeDiv = styled.div`
    animation: 2s ${FadeAnimation};
  `;

  return (
    <FadeDiv>
      <div className='fullpage homepage'>
        <h1>Hi, I am Yannick</h1>
        <h2>
          <span className='graphic'>Graphic Designer</span> and{' '}
          <span className='developer'>&lt;Front-End Designer&gt;</span>
        </h2>
        <Link to='/about'>
          <button className="btn">Get to know me</button>
        </Link>
      </div>
    </FadeDiv>
  );
}

export default Home;
