import React, { useState, useEffect } from 'react';

//CSS FILE IMPORT
import '../App.css';
import './Skills.css';

//ANIMATION IMPORT
import styled, { keyframes } from 'styled-components';
import { fadeInRightBig } from 'react-animations';

//PIE CHART IMPORT
import { PieChart } from 'react-minimal-pie-chart';

function Skills() {
  //ANIMATION
  const FadeAnimation = keyframes`${fadeInRightBig}`;
  const FadeDiv = styled.div`
    animation: 2s ${FadeAnimation};
  `;
  const [animationDone, setAnimationDone] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimationDone(true), 2000);
  }, []);

  const content = (
    <div className='fullpage skillspage'>
      <h1>Showing off my skillset</h1>
      <div className='line'></div>
      <p>
        Extensive experience with <span>Graphic Design</span> and Content
        Creation for marketing purposes. Design and concept of corporate
        identity, logo, offline and online marketing material. Professional knowledge of{' '}
        <span>Front-End Development</span> and UX/UI design. Decent technical
        programming knowledge.
      </p>
      <div className='row'>
        <div className='col-lg-4'>
          <h2 className='designer'>50%</h2>
          <h3>Graphic Designer</h3>
          <ul>
            <li>Logo design</li>
            <li>Corporate Identity</li>
            <li>InDesign</li>
            <li>Illustrator</li>
            <li>Photoshop</li>
            <li>After Effects</li>
          </ul>
        </div>
        <div className='col-lg-4'>
          <PieChart
            className='pieChart'
            data={[
              { title: 'Front-End Developer', value: 50, color: '#262626' },
              { title: 'Graphic Designer', value: 50, color: '#c3a484' },
            ]}
            animate
            reveal={animationDone ? 100 : 0}
            lineWidth={60}
          />
        </div>
        <div className='col-lg-4'>
          <h2 className='webdesigner'>50%</h2>
          <h3>Front-End Designer</h3>
          <ul>
            <li>HTML5/CSS3</li>
            <li>Bootstrap</li>
            <li>Javascript ES6</li>
            <li>React</li>
            <li>Adobe XD/Figma</li>
            <li>Low-Code/Webflow</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return animationDone ? <div>{content}</div> : <FadeDiv>{content}</FadeDiv>;
}

export default Skills;
